import React, {useState} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import emailjs from 'emailjs-com';
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import arrowBlack from "assets/img/arrow-black-icon.svg";
import pinYellow from "assets/img/pin-yellow-icon.svg";
import pinBlack from "assets/img/pin-black-icon.svg";

const Form = () => {
    const [isEmailSent, setIsSentEmail] = useState(false);
    const [isError, setIsError] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(
            'service_w5mosig',
            'template_n5r1s3c',
            e.target,
            'srUwzlTRa1ZuHk2Xm'
        ).then(res => {
            setIsSentEmail(true);
            console.log(res);
        }).catch(err => {
            setIsError(true);
            console.log(err);
        });
    }

    return (
        <ParallaxProvider>
            <section className="form-section" id="form-section">
                <Container>
                    <Row>
                        <Col lg={{span: 4, offset: 4}} sm={12}>
                            <h3>ОСТАВИТЬ ЗАЯВКУ</h3>
                            <form onSubmit={sendEmail}>
                                <div className="form-group">
                                    <input type="text" name="name" placeholder="Ваше имя*" className="form-control"
                                           required/>
                                </div>
                                <div className="form-group">
                                    <input type="tel" name="phone" placeholder="Ваш телефон*" className="form-control"
                                           required/>
                                </div>
                                <div className="form-group">
                                    <input type="email" name="email" placeholder="Ваш email*" className="form-control"
                                           required/>
                                </div>
                                <div className="form-group">
                                    <select name="service" className="form-control" defaultValue="D">
                                        <option value="Ничего не выбрано">Какая услуга вас интересует?</option>
                                        <option value="Автоперевозки">Автоперевозки</option>
                                        <option value="Грузоперевозки">Грузоперевозки</option>
                                        <option value="Опасные грузы">Опасные грузы</option>
                                    </select>
                                </div>
                                <button
                                    type="submit"
                                    className={`btn-main btn-yellow ${(isEmailSent || isError) && 'disabled'}`}
                                    disabled={isEmailSent || isError}
                                    aria-disabled={isEmailSent || isError}
                                >
                                    {isEmailSent
                                        ? 'Заявка отправлена'
                                        : isError
                                            ? 'Ошибка отправки'
                                            : 'Отправить заявку'
                                    }
                                    <span className="arrow">
                                    <img src={arrowBlack} alt="Arrow icon"/>
                                </span>
                                </button>
                            </form>
                        </Col>
                        <Col lg={{span: 4, offset: 4}} sm={12}>
                            <div className="contacts-wrap">
                                <h3>КОНТАКТЫ</h3>
                                <div className="contacts-links">
                                    <p>220088 г. Минск, ул. Захарова 104В</p>
                                    <a href="tel:+375296241401">+375 (29) 624-14-01</a>
                                    <a href="mailto:priorpartner.by@gmail.com">priorpartner.by@gmail.com</a>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="pattern">
                                <Parallax
                                    translateX={['70px', '-70px']}
                                    translateY={['70px', '-70px']}
                                    scale={[0.75, 1]}
                                    className="parallax"
                                >
                                    <img src={pinBlack} alt="Pin icon"/>
                                </Parallax>
                                <Parallax
                                    translateX={['-70px', '70px']}
                                    translateY={['70px', '-70px']}
                                    scale={[0.75, 1]}
                                    className="parallax"
                                >
                                    <img src={pinYellow} alt="Pin icon"/>
                                </Parallax>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </ParallaxProvider>
    );
}

export default Form;
