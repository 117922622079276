import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';
import {Link} from "react-scroll";

import arrowYellow from "assets/img/arrow-yellow-icon.svg";

import servicesImage from "assets/img/services-image.png";

const Services = ({screens = {}}) => {
    const {isMdScreen} = screens;

    const SERVICES_ITEMS = [
        {
            name: 'Автоперевозки'
        },
        {
            name: 'Грузоперевозки'
        },
        {
            name: 'Опасные грузы'
        },
    ]

    return (
        <ParallaxProvider>
            <section className="services-section" id="services-section">
                <Container>
                    <Row>
                        <Col lg={{span: 4, offset: 1}} md={5} sm={12}>
                            <h2>НАШИ УСЛУГИ</h2>
                            <div className="items">
                                {SERVICES_ITEMS.map((item, index) => (
                                    <Link
                                        to="form-section"
                                        duration={500}
                                        delay={300}
                                        key={index}
                                    >
                                        {item.name}
                                        <span className="arrow">
                                                <img src={arrowYellow} alt="Arrow icon"/>
                                            </span>
                                    </Link>
                                ))}
                            </div>
                        </Col>
                        <Col lg={{span: 5, offset: 1}} md={7} sm={12} className="image-wrap">
                            <img src={servicesImage} alt="Services image"/>
                            <div className="text">
                                <Parallax
                                    translateX={isMdScreen ? ['-50px', '50px'] : ['-100px', '100px']}
                                    className="parallax"
                                >
                                    <p>НАШИ УСЛУГИ</p>
                                </Parallax>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default Services;
