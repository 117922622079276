import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import heroBg from "assets/img/hero-image.png";
import pinYellow from "assets/img/pin-yellow-icon.svg";
import pinBlack from "assets/img/pin-black-icon.svg";

const Hero = ({screens = {}}) => {
    const {isMdScreen} = screens;

    return (
        <ParallaxProvider>
            <section className="hero-section" id="hero-section">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="bg">
                                <img src={heroBg} alt="Hero bg image"/>
                            </div>
                            <h1>МЕЖДУНАРОДНЫЕ<br/> ГРУЗОПЕРЕВОЗКИ<br/> И АВТОПЕРЕВОЗКИ</h1>
                            <div className="pattern">
                                <Parallax
                                    translateX={isMdScreen ? ['70px', '-40px'] : ['70px', '-70px']}
                                    translateY={['-70px', '70px']}
                                    scale={[0.75, 1]}
                                    className="parallax"
                                >
                                    <img src={pinYellow} className="yellow" alt="Pin icon"/>
                                </Parallax>
                                <Parallax
                                    translateX={isMdScreen ? ['-70px', '40px'] : ['-70px', '70px']}
                                    translateY={['70px', '-70px']}
                                    scale={[0.75, 1]}
                                    className="parallax"
                                >
                                    <img src={pinBlack} className="black" alt="Pin icon"/>
                                </Parallax>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default Hero;
