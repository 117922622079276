import React from 'react';
import {useMediaQuery} from 'react-responsive';

import Header from "../Header";
import Hero from "../Hero";
import Services from "../Services";
import Banner from "../Banner";
import About from "../About";
import Quote from "../Quote";
import Form from "../Form";
import Footer from "../Footer";

const Home = () => {
    const isXsScreen = useMediaQuery({query: '(max-width: 480px)'});
    const isSmScreen = useMediaQuery({query: '(max-width: 768px)'});
    const isMdScreen = useMediaQuery({query: '(max-width: 992px)'});
    const isLgScreen = useMediaQuery({query: '(max-width: 1200px)'});

    const screens = {
        isXsScreen,
        isSmScreen,
        isMdScreen,
        isLgScreen
    }

    return (
        <>
            <Header screens={screens}/>
            <main>
                <Hero screens={screens}/>
                <Services screens={screens}/>
                <Banner screens={screens}/>
                <About screens={screens}/>
                <Quote/>
                <Form/>
            </main>
            <Footer screens={screens}/>
        </>
    )
}

export default Home;
