import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';
import {Link} from "react-scroll";

import pinYellow from "assets/img/pin-yellow-icon.svg";
import arrowBlack from "assets/img/arrow-black-icon.svg";

const Banner = ({screens = {}}) => {
    const {isMdScreen} = screens;

    return (
        <ParallaxProvider>
            <section className="banner-section" id="banner-section">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3>Оставьте заявку и мы свяжемся<br/> с вами как можно скорее</h3>
                            <Link
                                to="form-section"
                                duration={500}
                                delay={300}
                                className="btn-main"
                            >
                                Оставить заявку
                                <span className="arrow">
                                    <img src={arrowBlack} alt="Arrow icon"/>
                                </span>
                            </Link>
                            <div className="pattern">
                                <Parallax
                                    translateX={isMdScreen ? ['70px', '-40px'] : ['70px', '-70px']}
                                    translateY={['-70px', '70px']}
                                    scale={[0.75, 1]}
                                    className="parallax"
                                >
                                    <img src={pinYellow} alt="Pin icon"/>
                                </Parallax>
                                <Parallax
                                    translateX={isMdScreen ? ['-70px', '40px'] : ['-70px', '70px']}
                                    translateY={['70px', '-70px']}
                                    scale={[0.75, 1]}
                                    className="parallax"
                                >
                                    <img src={pinYellow} alt="Pin icon"/>
                                </Parallax>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default Banner;
