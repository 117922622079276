import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import quoteGray from "assets/img/quote-gray-icon.svg";
import quoteYellow from "assets/img/quote-yellow-icon.svg";

const Qoute = () => (
    <section className="quote-section" id="quote-section">
        <Container>
            <Row>
                <Col xs={12}>
                    <h3>Мы доставим Ваш груз с любовью!</h3>
                    <div className="pattern">
                        <img src={quoteGray} alt="Quote icon"/>
                        <img src={quoteYellow} alt="Quote icon"/>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
)

export default Qoute;
