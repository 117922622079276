import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {ParallaxProvider, Parallax} from 'react-scroll-parallax';

import aboutImage from "assets/img/about-image.png";

const About = ({screens = {}}) => {
    const {isMdScreen} = screens;

    return (
        <ParallaxProvider>
            <section className="about-section" id="about-section">
                <Container>
                    <Row>
                        <Col lg={{span: 5, offset: 1}} md={6} sm={12} className="text-wrap">
                            <h2>О КОМПАНИИ</h2>
                            <p>«Приор Партнёр» — молодая компания, определяющая тренды в современной транспортной
                                логистике и гарантирующая качество предоставляемых услуг.<br/><br/> Опыт наших
                                сотрудников обеспечит безупречное качество доставки груза за счет:</p>
                            <ul>
                                <li>— использования современных технологий</li>
                                <li>— системного изучения логистических трендов</li>
                                <li>— непрерывного расширения своих возможностей под текущие требования рынка.</li>
                            </ul>
                        </Col>
                        <Col lg={5} md={6} sm={12} className="image-wrap">
                            <img src={aboutImage} alt="About image"/>
                            <div className="text">
                                <Parallax
                                    translateX={isMdScreen ? ['50px', '-50px'] : ['100px', '-100px']}
                                    className="parallax"
                                >
                                    <p>РАБОТАЕМ С 2014 ГОДА</p>
                                </Parallax>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </ParallaxProvider>
    )
}

export default About;
