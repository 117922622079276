import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-scroll";

import logo from "assets/img/logo.svg";

const Footer = ({screens = {}}) => {
    const {isMdScreen} = screens;

    const FOOTER_LINKS = [
        {
            name: 'Наши услуги',
            link: 'services-section'
        },
        {
            name: 'О компании',
            link: 'about-section'
        },
        {
            name: 'Контакты',
            link: 'contacts-section'
        },
    ];

    return (
        <footer id="contacts-section">
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="text">
                            <img src={logo} alt="Logo"/>
                            <p>© 2014-2023 ООО «Приор Партнёр»</p>
                        </div>
                        <div className="links">
                            {FOOTER_LINKS.map((el, index) => {
                                return <Link
                                    to={el.link}
                                    duration={500}
                                    delay={300}
                                    offset={isMdScreen ? -90 : -50}
                                    key={index}
                                >
                                    {el.name}
                                </Link>
                            })}
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
