import React, {useRef} from 'react';
import {Navbar, Container, Nav} from "react-bootstrap";
import {Link} from "react-scroll";

import logo from "assets/img/logo.svg";
import arrowBlack from "assets/img/arrow-black-icon.svg";

const Header = ({screens = {}}) => {
    const {isMdScreen} = screens;

    const NAVBAR_LINKS = [
        {
            name: 'Наши услуги',
            link: 'services-section'
        },
        {
            name: 'О компании',
            link: 'about-section'
        },
        {
            name: 'Контакты',
            link: 'contacts-section'
        },
    ];

    const navToggleRef = useRef(null);
    const handleNavLinkClick = () => {
        navToggleRef.current.click();
    }

    return (
        <header>
            <Navbar collapseOnSelect expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logo} alt="Logo"/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" ref={navToggleRef}>
                        <span className="icon-bar"/>
                        <span className="icon-bar"/>
                        <span className="icon-bar"/>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav navbarScroll>
                            {NAVBAR_LINKS.map((el, index) => {
                                return <Link
                                    to={el.link}
                                    duration={500}
                                    delay={300}
                                    offset={isMdScreen ? -90 : -50}
                                    key={index}
                                    onClick={handleNavLinkClick}
                                    className="nav-link"
                                >
                                    {el.name}
                                </Link>
                            })}
                        </Nav>
                        <Link
                            to="form-section"
                            duration={500}
                            delay={300}
                            onClick={handleNavLinkClick}
                            className="btn-main btn-yellow"
                        >
                            Оставить заявку
                            <span className="arrow">
                                <img src={arrowBlack} alt="Arrow icon"/>
                            </span>
                        </Link>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
